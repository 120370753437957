.smbgModal{
    .modal-dialog{
        max-width: 580px;
        .modal-content{
            border-radius: 15px;
        }
        .modal-header{
            border: none;
        }
    }
    .showSmbgModalBody.modal-body {
        position: relative;
        width: auto;
        box-shadow: none;
        background-color: transparent;
        padding-top: 0;
        .top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 36px;
    
            h3 {
                color: #313131;
                font-family: "Circular Std";
                font-size: 22px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;
                padding-top: 0;
                margin-left: 0;
            }
        }
    
        .wraperInfo_bottom {
            display: flex;
            flex-direction: column;
            gap: 29px;
            justify-content: center;
            align-items: center;
            margin-bottom: 53px;
    
            .wrape-bar {
                width: 100%;
                max-width: 485px;
                display: flex;
                gap: 22px;
    
                .single {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
    
                    label {
                        color: #000;
                        margin-bottom: 8px;
                        font-family: "Circular Std";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: normal;
                    }
    
                    input {
                        display: flex;
                        width: 147px;
                        height: 43px;
                        padding: 6.794px 13.588px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 8.492px;
    
                        border-radius: 6px;
                        border: 0.3px solid #959494;
                    }
                }
            }
        }
    
        .btnsWraping {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
    
            .cancelBtn {
                width: 240.55px;
                height: 40.8px;
                flex-shrink: 0;
    
                border-radius: 8.5px;
                background: #D9D9D9;
                color: #959494;
                text-align: center;
                font-family: Nunito;
                font-size: 15.3px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.765px;
            }
    
            .btn_wrape {
                width: 240.55px;
                height: 40.8px;
                flex-shrink: 0;
    
                border-radius: 8.5px;
                background: #29BCC1;
                display: flex;
                align-items: center;
                overflow: hidden;
    
                button {
                    width: 240.55px;
                    height: 100%;
                    color: #FFF;
                    text-align: center;
                    font-family: Nunito;
                    font-size: 15.3px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.765px;
                }
    
                .arrow_wrape {
                    width: 40.8px;
                    height: 100%;
                    flex-shrink: 0;
                    border-radius: 0px 10px 10px 0px;
                    background: #078A8E;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .arrow {
                        width: 13.866px;
                        height: 11.395px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
