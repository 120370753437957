.prescription {
    .wraperForm {
        width: 100%;
        max-width: 100%;
        flex-shrink: 0;
        border-radius: 12px;
        background: #FFF;
        position: relative;

        .custom-scrollbar {
            height: 380px;
            overflow-y: scroll;
            overflow-x: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .bottom-print {
            background-color: #FFFFFF;
            height: 93px;
            box-shadow: 0px -4px 20px 0px #D8DAE233;
            border-radius: 0px 0px 15px 15px;
            text-align: right;

            .btn {
                background-color: #29BCC1;
                width: 283px;
                height: 48px;
                border-radius: 10px;
                margin: 22px 27px 23px 0;
                text-transform: uppercase;
                border: none;
                font-size: 18px;
                font-family: Nunito;
                font-weight: 800;
                text-align: center;
                color: #FFFFFF;
                line-height: 24.55px;
            }
        }

        .labTable {
            .labTableBody {
                tr {
                    td {
                        color: #313131;
                        text-align: left;
                        font-family: "Circular Std";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: normal;
                    }
                }
            }

            .labTableThead {
                tr {
                    th {
                        color: #313131;
                        text-align: left;
                        font-family: "Circular Std";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }

        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border: none;
                        background-color: #F5F5F5;
                        color: #313131;
                        font-size: 20px;
                        font-weight: 500;
                        font-family: "Circular Std";
                        line-height: 25.3px;
                        text-align: center;

                        &:nth-child(1) {
                            text-align: left;
                            padding-left: 24px;
                        }
                    }
                }
            }

            tbody {
                .gen {
                    td {
                        padding: 12px 11px;
                        border-bottom: 1px solid #078A8E;
                        border-top: none;
                    }
                }
                tr {

                    td {
                        padding: 25px 0 24px;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 500;
                        font-family: "Circular Std";
                        line-height: 22.77px;
                        color: #313131;

                        &:nth-child(1) {
                            text-align: left;
                            padding-left: 24px;
                        }

                        .gender {
                            justify-content: space-around;
                            display: flex;
                            align-items: center;

                            span {
                                color: #078A8E;
                            }
                        }

                        .gender-number {
                            display: flex;
                            justify-content: space-around;
                            padding: 0 11px;
                        }
                    }
                }
            }
        }
    }

    .date {
        margin-bottom: 26px;

        label {
            font-size: 18px;
            font-weight: 300;
            font-family: "Circular Std";
            color: #313131;
            width: 100%;
        }

        .date-input {
            border: 0.3px solid #404040;
            border-radius: 10px;
            display: inline-flex;
            position: relative;
            align-items: center;
            width: 280px;
            height: 44px;
            padding: 7px 16px 8px;
            font-family: "Circular Std";
            font-size: 20px;
            font-weight: 300;
            background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M1 1L6.85858 6.85858C6.93668 6.93668 7.06332 6.93668 7.14142 6.85858L13 1' stroke='%23383838' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 93% center;

            .ant-picker {
                background: initial;
            }

            .ant-picker-clear {
                display: none;
            }

            input {
                color: #959494;
                font-family: "Circular Std";
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                text-align: left;
                padding-left: 2rem;
                line-height: normal;
            }

            .arrow {
                position: absolute;
                top: 18px;
                height: 6px;
                right: 0;
            }

            // img {
            //     padding-right: 20px;
            // }

            .ant-picker {
                width: 100%;
                border: none;
                padding-left: 0;

                .ant-picker-input {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }
}

.dietaryAssessment-border .wraperForm td{
    border-bottom: none;
    border-top: none;
}