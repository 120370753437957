.editModal {
    .modal-dialog{
        max-width: 580px;
        .modal-content{
            box-shadow: 0px -4px 20px 0px #D8DAE233;
            border-radius: 15px;
        }
    }
    .modal-header{
        border: none;
    }
    .modal-body{
        padding: 0 32px 39px 39px;
        label{
            margin-bottom: 8px;
            width: 100%;
            color: #000000;
            font-weight: 450;
            font-family: "Circular Std";
            font-size: 16px;
            line-height: 20.24px;
        }
        textarea{
            width: 100%;
            padding: 6.79px 13.59px 6.79px 13.59px;
            border: 0.3px solid #959494;
            border-radius: 6px;
            resize: none;
        }
    }
}