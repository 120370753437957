.createBookingModalBody {
    width: 100%;
    padding: 0;

    .wraperInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 30.4px;
        padding-top: 20.9px;
        padding-right: 20.9px;
        padding-bottom: 36.57px;

        .cross_icon_modal {
            width: 16px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M9.47885 8.13258L15.7348 14.3886L14.3886 15.7348L8.13258 9.47885L8 9.34626L7.86742 9.47885L1.61143 15.7348L0.265165 14.3886L6.52115 8.13258L6.65374 8L6.52115 7.86742L0.265165 1.61143L1.61143 0.265165L7.86742 6.52115L8 6.65374L8.13258 6.52115L14.3886 0.265165L15.7348 1.61143L9.47885 7.86742L9.34626 8L9.47885 8.13258Z' fill='%23313131' stroke='white' stroke-width='0.375'/%3E%3C/svg%3E");
             background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            align-self: flex-end;
            cursor: pointer;
        }

        h2 {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #313131;
            text-align: center;
            font-family: Nunito;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .kh-patientType{
            font-family: Circular Std;
            font-size: 16px;
            font-weight: 450;
            line-height: 19.35px;
            text-align: left;
            color: #535151;
            margin-top: 40px;
            margin-left: 78px;
        }

        .consultation-type{
            display: flex;
            gap: 57px;
            align-items: center;
            justify-content: start;
            margin-top: 15px;
            margin-left: 75px;
            
            .single {
                display: flex;
                gap: 17px;
                align-items: center;

                label {
                    color: #313131;
                    font-family: "Circular Std";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }

        .patients-ask {
            display: flex;
            gap: 33px;
            align-items: center;
            justify-content: center;
            margin-bottom: -15px;
            margin-top: 15px;

            .single {
                display: flex;
                gap: 17px;
                align-items: center;

                label {
                    color: #313131;
                    font-family: "Circular Std";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }

        .ErrorState {
            color: #bc0001;
            font-weight: 500;
            font-size: 12px;
            padding-left: 76px;
            margin-top: 5px;    
        }

        
        .ErrorState1 {
            color: #bc0001;
            font-weight: 500;
            font-size: 12px;
            padding-left: 76px;
            padding-top: 12px;
        }
        .kh-margintop{
            margin-top: 15px;
        }

        .continueBtn {
            width: 168px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #29BCC1;
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.8px;
            margin: auto;
            margin-top: 30px;
        }
        .continueBtnNo {
            width: 168px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #fff;
            color: #313131;


            text-align: center;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.8px;
            margin: auto;
            border-radius: 9px;
border: 0.4px solid #313131;
        }
    }
}

.checks_radio_custom1{
    position: relative;
input{
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    &:checked + label:after{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z' fill='%2319B3B5'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;background-size: 14px;
    }
}
label{
    position: relative;
    padding-left: 35px;

    &:after{
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #D9D9D9;
        display: inline-block;
    left: 0;
    top: 0;
    position: absolute;
    }
}
}
.saveassessmentModal {
     .modal-content{
        height: 302px!important;
        .modal-body{height: 100%!important;}
    }
}