.verifyOtp {
    width: 100%;
    height: calc(100vh - 76px);
    background: rgba(195, 231, 232, 0.80);
    display: flex;
    overflow: hidden;

    .left {

        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            bottom: 0;
            width: 550.936px;
            height: 532px;
            object-fit: cover;
        }
    }

    .right {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: start;
        padding-top: 44px;

        .card {
            width: 544px;
            // height: 586px;
            flex-shrink: 0;
            padding: 38px 39px 39px;
            border-radius: 12px;
            background: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // gap: 31px;

            h1 {
                color: #313131;
                text-align: center;
                font-family: Nunito;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 37px;
                line-height: 45px;
            }

            .timeExpires {
                display: flex;
                flex-direction: column;
            }

            .email-sent {
                color: #313131;
                text-align: center;
                font-family: "Circular Std";
                font-size: 18px;
                margin-bottom: 46px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                .name-high {
                    color: #313131;
                    font-family: "Circular Std";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: normal;
                }
            }

            .otp-login-box {
                margin-bottom: 46px;

                .otp-contain {
                    display: flex;
                    gap: 15.69px;

                    input {
                        width: 102.31px;
                        height: 108.373px;
                        border: 0.3px solid #313131;
                        color: #313131;
                        text-align: center;
                        font-family: Nunito;
                        font-size: 36px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 45px;
                        /* 125% */
                    }
                }
            }

            .otpError {
                input {
                    border: 0.3px solid #C20203;
                    color: #C20203;
                }
            }

            .toast-alert {
                display: flex;
                width: 457px;
                height: 31px;
                padding: 14px 15px;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                border-radius: 6px;
                background: #FEE6E6;
                color: #B11B1B;
                font-family: "Circular Std";
                font-size: 13px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                /* 18.2px */
                letter-spacing: 0.13px;

                .cross-icon {
                    width: 18px;
                    height: 18px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none'%3E%3Cpath d='M14.25 5.3075L13.1925 4.25L9 8.4425L4.8075 4.25L3.75 5.3075L7.9425 9.5L3.75 13.6925L4.8075 14.75L9 10.5575L13.1925 14.75L14.25 13.6925L10.0575 9.5L14.25 5.3075Z' fill='%23B11B1B'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    cursor: pointer;
                }
            }

            .resend-wrape {
                display: flex;
                flex-direction: column;
                margin-bottom: 33px;
                align-items: center;
                gap: 8px;

                p.resend {
                    font-family: "Circular Std";
                }

                .resend_sapn {
                    color: #EF6286;
                    text-align: center;
                    font-family: "Circular Std";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-decoration-line: underline;
                    margin-top: 0;
                }

                .resend-desc {
                    color: #313131;
                    text-align: center;
                    font-family: "Circular Std";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: normal;
                    margin-top: 9px;
                }
            }

            .btn_wrape {
                width: 493px;
                height: 53px;
                flex-shrink: 0;
                border-radius: 10px;
                background: #29BCC1;
                display: flex;
                align-items: center;
                overflow: hidden;

                button {
                    width: 440px;
                    height: 100%;
                    color: #FFF;
                    text-align: center;
                    font-family: Nunito;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.9px;
                }

                .arrow_wrape {
                    width: 53px;
                    height: 100%;
                    flex-shrink: 0;
                    border-radius: 0px 10px 10px 0px;
                    background: #078A8E;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .arrow {
                        width: 13px;
                        height: 15px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}