.labReports {
    width: 100%;
    max-width: 100%;
    height: 579px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FFF;
    padding-top: 36px;
    padding-bottom: 43px;
    padding-left: 24px;
    padding-right: 35px;
    gap: 23px;
    position: relative;

    .form {
        height: 100%;

        .table-responsive {
            overflow: scroll;
            height: 510px;

            &::-webkit-scrollbar-track {
                border-radius: 0;
                background-color: #D9D9D9;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #D9D9D9;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color: #95949466;
            }

            &::-webkit-scrollbar:horizontal {
                height: 6px;
            }
        }

        table {
            tr {
                th {
                    padding: 15px 20px 12px;
                    font-size: 18px;
                    text-align: center;
                    vertical-align: middle;
                    font-weight: 500;
                    color: #19B3B5;
                    font-family: 'Circular Std';
                    position: sticky;
                    top: 0;
                    background-color: white;
                }

                td {
                    font-size: 16px;
                    padding: 15px 20px 12px;
                    text-align: center;
                    font-weight: 450;
                    color: #313313;
                }

                .btn {
                    background-color: transparent;
                    border: none;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .view {
                    color: #19B3B5;
                    font-size: 12.5px;
                    font-family: "Circular Std";
                    font-weight: 500;
                    line-height: 15.81px;
                    border: 1.25px solid #19B3B5;
                    width: 65px;
                    height: 31.25px;
                    border-radius: 9.23px;
                    text-align: center;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}