.labReportModalBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;

    .wraperInfo { 
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding-left: 0;
        padding-right: 0;
        margin-left: -10px;
        padding-top: 5px;
        padding-bottom: 20px;

        .cross_icon_modal {
            width: 16px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'%3E%3Cpath d='M9.48796 7.14111L15.7153 12.59L14.3886 13.7509L8.12347 8.26889L8 8.16086L7.87653 8.26889L1.61143 13.7509L0.284736 12.59L6.51204 7.14111L6.67331 7L6.51204 6.85889L0.284736 1.41L1.61143 0.249144L7.87653 5.73111L8 5.83914L8.12347 5.73111L14.3886 0.249144L15.7153 1.41L9.48796 6.85889L9.32669 7L9.48796 7.14111Z' fill='%23313131' stroke='white' stroke-width='0.375'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            align-self: flex-end;
            cursor: pointer;
        }

        h2 {
            color: #313131;
            font-family: "Circular Std";
            font-size: 22px;
            font-style: normal;
            font-weight: 450;
            line-height: normal;
        }
    }

    .main-wraping {
        width: 100%;
        display: flex;
        gap: 41px;
        align-items: start;

        .left {
            width: 718px;
            display: flex;
            flex-direction: column;
            gap: 27px;

            .top {
                display: flex;
                align-items: center;
                gap: 21px;

                .single {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    gap: 8px;
                    position: relative;
                    .ErrorState{
                        color: #bc0001;
                        font-weight: 500;
                        font-size: 12px;
                        position: absolute;
                        bottom: -25px;
                    }
                    .datePick{
                        height: 43px;
                        padding: 6.794px 13.588px;
                        border-radius: 6px;
                        border: 0.3px solid #959494; 
                       
                        padding: 0 14px;
                        width: 147px;
                        .ant-picker-input{
                            input{    font-family: "Circular Std";
                            &::placeholder {
                                color: #5E6366;
                                opacity: 1; /* Firefox */
                              }
                            }
                            .ant-picker-suffix{display:none;}
                        }
                    }
                    label {
                        color: #000;
                        font-family: "Circular Std";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: normal;
                    }

                    .dateInp {
                        width: 147px;
                        height: 43px;
                        border-radius: 6px;
                        border: 0.3px solid #959494;
                        padding-left: 13.59px;
                        padding-right: 13.59px;
                    }
                    
                    .labInp {
                        display: flex;
                        width: 548px;
                        height: 43px;
                        padding: 6.794px 13.588px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 8.492px;
                        border-radius: 6px;
                        border: 0.3px solid #959494;
                        padding-left: 13.59px;
                    }
                }
            }

            .bottom {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: start;
                justify-content: space-between;
                .singling {
                    width: 22.6%;
                    padding-right: 20px;
                    .accordion-item{
                    border: 0px;
                        button.accordion-button{
                            padding:20px 0 0 0 ;
                            .wraping {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                h3 {
                                    color: #000;
                                    font-family: "Circular Std";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 450;
                                    line-height: normal;
                                }

                                .plusIcon {
                                    width: 16px;
                                    height: 16px;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M7.33333 11.3333H8.66667V8.66667H11.3333V7.33333H8.66667V4.66667H7.33333V7.33333H4.66667V8.66667H7.33333V11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V3.33333H3.33333V12.6667Z' fill='%2319B3B5'/%3E%3C/svg%3E");
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    background-position: center;
                                }
                            }
                        }
                        .accordion-body{padding: 0!important;}
                    }
                    .inputFieldNum {
                        display: flex;
                        width: 147px;
                        height: 47px;
                        padding: 6.794px 13.588px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 8.492px;
                        border-radius: 6px;
                        border: 0.3px solid #959494;
                        color: var(--Black-4, #5E6366);
                        font-family: "Circular Std";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;

                        &::placeholder {
                            color: var(--Black-4, #5E6366);
                            font-family: "Circular Std";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }

                        &::-webkit-inner-spin-button {
                            appearance: none;
                        }
                    }
                }
            }
        }


        .right {
            .single {
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 8px;

                label {
                    color: #000;
                    font-family: "Circular Std";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: normal;
                }

                textarea {
                    display: flex;
                    width: 548px;
                    height: 406px;
                    padding: 6.794px 13.588px;
                    flex-direction: column;
                    align-items: center;
                    gap: 8.492px;
                    flex-shrink: 0;
                    border-radius: 6px;
                    border: 0.3px solid #959494;
                    padding-left: 13.49px;
                    padding-top: 5px;
                    outline: none;
                    resize:none;

                    &::placeholder {
                        color: var(--Black-4, #5E6366);
                        font-family: "Circular Std";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                }
            }
        }
    }

    .btnsWraping {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 40px;
        .cancelBtn {
            width: 283px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #D9D9D9;
            color: #959494;
            text-align: center;
            font-family: Nunito;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.9px;
        }

        .btn_wrape {
            width: 283px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #29BCC1;
            display: flex;
            align-items: center;
            overflow: hidden;

            button {
                width: 283px;
                height: 100%;
                color: #FFF;
                text-align: center;
                font-family: Nunito;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.9px;
            }

            .arrow_wrape {
                width: 48px;
                height: 100%;
                flex-shrink: 0;
                border-radius: 0px 10px 10px 0px;
                background: #078A8E;
                display: flex;
                justify-content: center;
                align-items: center;

                .arrow {
                    width: 7.866px;
                    height: 13.395px;
                    object-fit: cover;
                }
            }
        }
    }
}

// new code
.viewModal {
    padding-left: 0 !important;
    position: relative;
            .cross_icon_modal {
            width: 16px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'%3E%3Cpath d='M9.48796 7.14111L15.7153 12.59L14.3886 13.7509L8.12347 8.26889L8 8.16086L7.87653 8.26889L1.61143 13.7509L0.284736 12.59L6.51204 7.14111L6.67331 7L6.51204 6.85889L0.284736 1.41L1.61143 0.249144L7.87653 5.73111L8 5.83914L8.12347 5.73111L14.3886 0.249144L15.7153 1.41L9.48796 6.85889L9.32669 7L9.48796 7.14111Z' fill='%23313131' stroke='white' stroke-width='0.375'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            align-self: flex-end;
            cursor: pointer;
        }
    .modal-dialog{
        max-width: 1391px;
        .modal-body{
            padding: 30px 30px 23px;
            .wraperInfo {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 13px;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 20px;
        
                h2 {
                    color: #313131;
                    font-family: "Circular Std";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: normal;
                }
            }
        
            .main-wraping {
                width: 100%;
        
                .left {
                    width: 718px;
                    display: flex;
                    flex-direction: column;
                    gap: 27px;
        
                    .top {
                        display: flex;
                        align-items: center;
                        gap: 21px;
        
                        .single {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            gap: 8px;
                            
                            label {
                                color: #000;
                                font-family: "Circular Std";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 450;
                                line-height: normal;
                            }
        
                            .dateInp {
                                width: 147px;
                                height: 43px;
                                border-radius: 6px;
                                border: 0.3px solid #959494;
                                padding-left: 13.59px;
                                padding-right: 13.59px;
                            }
                            .noIcon{ 
                                 .ant-picker-suffix {
                                    opacity: 0;
                                }
                            }
                            .labInp {
                                display: flex;
                                width: 548px;
                                height: 43px;
                                padding: 6.794px 13.588px;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 8.492px;
                                border-radius: 6px;
                                border: 0.3px solid #959494;
                                padding-left: 13.59px;
                            }
                        }
                    }
        
                    .bottom {
                        width: 100%;
                        .singling {
                            .accordion-item {
                                border: none;
                                margin-bottom: 20px;
                                .accordion-body {
                                    padding: 0;
                                }
                                .accordion-button {
                                    padding: 0;
                                }
                            }
        
                            .wraping {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
        
                                h3 {
                                    color: #000;
                                    font-family: "Circular Std";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 450;
                                    line-height: normal;
                                }
        
                                .plusIcon {
                                    width: 16px;
                                    height: 16px;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M7.33333 11.3333H8.66667V8.66667H11.3333V7.33333H8.66667V4.66667H7.33333V7.33333H4.66667V8.66667H7.33333V11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V3.33333H3.33333V12.6667Z' fill='%2319B3B5'/%3E%3C/svg%3E");
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    background-position: center;
                                }
                            }
        
                            .inputFieldNum {
                                display: flex;
                                width: 100%;
                                height: 47px;
                                padding: 6.794px 13.588px;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 8.492px;
                                border-radius: 6px;
                                border: 0.3px solid #959494;
                                color: var(--Black-4, #5E6366);
                                font-family: "Circular Std";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
        
                                &::placeholder {
                                    color: var(--Black-4, #5E6366);
                                    font-family: "Circular Std";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
        
        
                .right {
                    .single {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        gap: 8px;
                       
                        label {
                            color: #000;
                            font-family: "Circular Std";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 450;
                            line-height: normal;
                        }
        
                        textarea {
                            display: flex;
                            width: 523px;
                            height: 406px;
                            padding: 5px 14px;
                            flex-direction: column;
                            align-items: center;
                            gap: 8.492px;
                            flex-shrink: 0;
                            border-radius: 6px;
                            border: 0.3px solid #959494;
                            outline: none;
        
                            &::placeholder {
                                color: var(--Black-4, #5E6366);
                                font-family: "Circular Std";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                            }
        
                        }
                    }
                }
            }
        }
        .btnsWraping {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
    
            .cancelBtn {
                width: 283px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 10px;
                background: #D9D9D9;
                color: #959494;
                text-align: center;
                font-family: Nunito;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.9px;
                text-transform: uppercase;
            }
    
            .btn_wrape {
                width: 283px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 10px;
                background: #29BCC1;
                display: flex;
                align-items: center;
                overflow: hidden;
    
                button {
                    width: 283px;
                    height: 100%;
                    color: #FFF;
                    text-align: center;
                    font-family: Nunito;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.9px;
                    span{position: relative;
                        left: 10px;}
                }
    
                .arrow_wrape {
                    width: 48px;
                    height: 100%;
                    flex-shrink: 0;
                    border-radius: 0px 10px 10px 0px;
                    background: #078A8E;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .arrow {
                        width: 13.866px;
                        height: 7.395px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft {
    z-index: 9999;
}