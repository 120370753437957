.medicalHistory {
    width: 100%;
    max-width: 100%;
    height: 559px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FFF;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 43px;
    padding-left: 24px;
    padding-right: 35px;
    gap: 23px;
    position: relative;

    table {
        tr {
            th {
                padding: 18px 35px 15px;
                font-size: 16px;
                font-weight: 450;
                color: #19B3B5;
                font-family: 'Circular Std';
            }

            td {
                font-size: 16px;
                padding: 15px 35px 12px;
                border-right: none;
                border-left: none;
                border-top: .5px solid #D9D9D9 !important;
                font-weight: 450;
                color: #313313;
                font-family: "Circular Std";

                .btn {
                    background-color: transparent;
                    border: none;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .continueBtn {
        width: 177px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #29BCC1;
        color: #FFF;
        text-align: center;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.9px;
        display: flex;
        align-self: flex-end;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

.responss {
    width: 100px; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}