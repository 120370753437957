.vitals {
    width: 100%;
    max-width: 100%;
    height: 559px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FFF;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    gap: 23px;
    position: relative;

    .wraperForm {
        .table-responsive {
            overflow: scroll;
            height: 510px;

            &::-webkit-scrollbar-track {
                border-radius: 0;
                background-color: #D9D9D9;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #D9D9D9;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                background-color: #95949466;
            }

            &::-webkit-scrollbar:horizontal {
                height: 6px;
            }
        }

        .table {

            .vitals-tr{
                td {
                    border-top: 0.3px solid #313131;
                }
            }
            tr {
                th {
                    color: #19B3B5;
                    font-size: 18px;
                    font-weight: 450;
                    text-align: center;
                    // border-bottom: 1.51px solid #19B3B5;
                    font-family: 'Circular Std';
                    padding-left: 28px;
                    padding-right: 28px;
                    position: sticky;
                    top: 0;
                    background-color: white;
                }

                td {
                    text-align: center;
                    padding: 14px .5rem;
                    // border-bottom: none;
                    border-right: none;
                    font-weight: 300;
                    font-family: 'Circular Std';
                    font-size: 18px;
                    color: #313131;


                    .btn {
                        background-color: transparent;
                        border: none;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    .print {
                        color: #19B3B5;
                        font-size: 12.5px;
                        font-weight: 500;
                        line-height: 15.81px;
                        border: 1.25px solid #19B3B5;
                        width: 65px;
                        height: 31.25px;
                        border-radius: 9.23px;
                        text-align: center;
                    }
                }
            }
        }
    }
}