
.medicalHistoryEducator {
    width: 100%;
    max-width: 1488px;
    height: 559px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FFF;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 43px;
    padding-left: 0;
    padding-right: 0;
    gap: 23px;
    position: relative;

    .table-data-labs-report {
        tr{
            th{
                text-align: left;
                font-size: 18px!important;
                padding: 20px 25px;
            }
            td{    padding: 17px 25px;
                color: #313131;
                font-family: "Circular Std";
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;}
        }
    }

    .wraperForm {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: start;
        justify-content: start;
        gap: 58px;

        .wraperSingle {
            display: flex;
            align-items: center;
            gap: 16px;

            label {
                color: #535151;
                font-family: "Circular Std";
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;
            }

            .wrapingCheck {
                height: 132px;
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 14px;

                .checks-single-wraper {
                    display: flex;
                    width: 154px;
                    height: 43px;
                    padding: 8.807px 15.413px;
                    align-items: center;
                    gap: 11.009px;
                    border-radius: 22.018px;
                    border: 0.5px solid #959494;

                    .yes-single {
                        display: flex;
                        gap: 12px;
                        align-items: center;

                        label {
                            color: var(--Neutral-10, #12161C);
                            font-family: Nunito;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 22.018px;
                            /* 137.612% */
                            letter-spacing: -0.32px;
                            text-transform: capitalize;
                        }

                        input {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }

            textarea {
                resize: none;
                width: 504px;
                height: 113px;
                flex-shrink: 0;
                border-radius: 6px;
                border: 0.3px solid #959494;
                color: #313131;
                font-family: "Circular Std";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding-left: 16px;
                padding-top: 10px;
                outline: none;

                &::placeholder {
                    color: #313131;
                    font-family: "Circular Std";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
    }

    .continueBtn {
        width: 177px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #29BCC1;
        color: #FFF;
        text-align: center;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.9px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 32px;
        bottom: 29px;
    }
}