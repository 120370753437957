.switchDoctorModalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;

    .wraperInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding-left: 39px;
        padding-right: 24px;
        padding-top: 20px;
        padding-bottom: 20px;

        .cross_icon_modal {
            width: 16px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'%3E%3Cpath d='M9.48796 7.14111L15.7153 12.59L14.3886 13.7509L8.12347 8.26889L8 8.16086L7.87653 8.26889L1.61143 13.7509L0.284736 12.59L6.51204 7.14111L6.67331 7L6.51204 6.85889L0.284736 1.41L1.61143 0.249144L7.87653 5.73111L8 5.83914L8.12347 5.73111L14.3886 0.249144L15.7153 1.41L9.48796 6.85889L9.32669 7L9.48796 7.14111Z' fill='%23313131' stroke='white' stroke-width='0.375'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            align-self: flex-end;
            cursor: pointer;
        }

        h2 {
            color: #313131;
            font-family: Nunito;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }


        .single {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 5px;
            margin-bottom: 9px;

            label {
                color: #535151;
                font-family: "Circular Std";
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: normal;
            }

            .currentDocInp {
                width: 456px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 6px;
                border: 0.3px solid #959494;
                background: #F2F2F2;
                padding-left: 11px;
                color: #313131;
                font-family: "Circular Std";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .placeholderStyle{
                font-family: Circular Std;
                font-size: 16px;
                font-weight: 300;
                line-height: 22.58px;
                text-align: left;
                color: #313131;
            }

            .switchDocInp {
                width: 456px;
                height: 48px;
                border-radius: 6px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8' fill='none'%3E%3Cpath d='M1 1L6.85858 6.85858C6.93668 6.93668 7.06332 6.93668 7.14142 6.85858L13 1' stroke='%23313131' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: 97% center;
                padding: 0;
                color: #313131;
                font-family: "Circular Std";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                &:hover {
                    border-color: transparent; 
                }

                option {
                    border-radius: 8px;
                    background: #FFF;
                    box-shadow: 0px 0px 20px 0px rgba(142, 142, 142, 0.15);
                } 
            }
            
        }
    }

    .divider {
        width: 100%;
        height: 0.3px;
        background: #959494;
        margin-top: 29px;
    }


    .continueBtnWraper {
        width: 456px;
        height: 53px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #29BCC1;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin: auto;

        button {
            width: 440px;
            height: 100%;
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.9px;
        }

        .arrow_wrape {
            width: 53px;
            height: 100%;
            flex-shrink: 0;
            border-radius: 0px 10px 10px 0px;
            background: #078A8E;
            display: flex;
            justify-content: center;
            align-items: center;

            .arrow {
                width: 17px;
                height: 17px;
                object-fit: contain;
            }
        }
    }
}