.add__btn {
    width: 97px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #29BCC1;
    display: flex;
    align-self: start;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.9px;
    margin-bottom: 26px;
}

.labReportsAppointments {

    .wraperAll {
        width: 100%;
        max-width: 1488px;
        height: 491px;
        border-radius: 12px;
        background: #FFF;
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 43px;
        padding-left: 0;
        padding-right: 0;
        gap: 23px;
        position: relative;
        margin: auto;

        .wraperLabsTable {
            width: 100%;
            height: 400px; 
            overflow-y: scroll;
            padding-top: 16px;

            .table-responsive {
                height: 380px;
                &::-webkit-scrollbar-track
                {
                    border-radius: 0;
                    background-color: #D9D9D9;
                }

                &::-webkit-scrollbar
                {
                    width: 6px;
                    background-color: #D9D9D9;
                }

                &::-webkit-scrollbar-thumb
                {
                    border-radius: 6px;
                    background-color: #95949466;
                }
                &::-webkit-scrollbar:horizontal {
                    height: 6px;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .table-data-labs-report {
                thead{
                    th{
                        padding-left: 31px;
                        padding-right: 31px;
                        position: sticky;
                        top: 0;
                        background-color: white;
                    }
                }

                tbody {
                    td {
                        text-align: center;
                    }
                }
            }
        }
        tr{
            .table-td{
                padding: 130px 0;
                border: none;
            }
        }

        .empyAddRec {
            position: sticky;
            left: 0;
            right: 0;
            top: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0;
            color: #959494;
text-align: center;
font-family: "Circular Std";
font-size: 22px;
font-style: normal;
font-weight: 300;
line-height: normal;
        }

        .continueBtn {
            width: 177px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #29BCC1;
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.9px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 32px;
            bottom: 29px;
        }
    }
}