.add__btn {
    width: 97px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #29BCC1;
    display: flex;
    align-self: start;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.9px; 
    margin-bottom: 26px;
}


.labReportsEducator {

    .wraperAll {
        width: 100%;
        max-width: 1488px;
        height: 579px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #FFF;
        margin: auto;
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 43px;
        padding-left: 0;
        padding-right: 0;
        gap: 23px;
        position: relative;

        .wraperLabsTable {
            width: 100%;
            height: 91%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            .table-responsive{
                overflow-x: scroll;
                height: 480px;
                &::-webkit-scrollbar-track {
                    border-radius: 0;
                    background-color: #D9D9D9;
                }
    
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #D9D9D9;
                }
    
                &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background-color: #95949466;
                }
    
                &::-webkit-scrollbar:horizontal {
                    height: 6px;
                }
            }

            .table-data-labs-report {
                tr{
                    th{
                        padding: 20px 45px 8px;
                        height: 70px;
                        vertical-align: middle;
                    }
                    td {
                        text-align: center;
                        color: #313131;
                        text-align: center;
                        font-family: "Circular Std";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding: 15px 0;
                    }
                }
            }
        }

        .continueBtn {
            width: 177px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #29BCC1;
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.9px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 32px;
            bottom: 29px;
        }
    }
}