.modalpatientDetails{
    .modal-content{border-radius: 12px;    width: 537px;}
.patientModalBody {
    max-width: 536px;
    padding: 31px 32px 47px;
    position: relative;
    .wraperInfo {
        width: 100%;

        .cross_icon_modal {
            width: 16px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'%3E%3Cpath d='M9.48796 7.14111L15.7153 12.59L14.3886 13.7509L8.12347 8.26889L8 8.16086L7.87653 8.26889L1.61143 13.7509L0.284736 12.59L6.51204 7.14111L6.67331 7L6.51204 6.85889L0.284736 1.41L1.61143 0.249144L7.87653 5.73111L8 5.83914L8.12347 5.73111L14.3886 0.249144L15.7153 1.41L9.48796 6.85889L9.32669 7L9.48796 7.14111Z' fill='%23313131' stroke='white' stroke-width='0.375'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }

        h2 {
            width: 100%;
            color: #313131;
            font-family: Nunito;
            font-size: 20px;
            margin-bottom: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .patients-detail {
            margin-bottom: 25px;
            ul{
                margin-bottom: 21px;
                list-style-type: none;
                display: flex;
                justify-content: space-between;
                li{
                    display: inline-block;
                    label{
                        font-size: 18px;
                        font-weight: 300;
                        margin-left: 12px;
                        line-height: 25.4px;
                        color: #313131;
                    }
                    input[type=checkbox] {
                        position: relative;
                        border: 1px solid #D9D9D9;
                        border-radius: 4px;
                        background: none;
                        cursor: pointer;
                        line-height: 0;
                        outline: 0;
                        padding: 0 !important;
                        vertical-align: text-top;
                        height: 24px;
                        width: 24px;
                        -webkit-appearance: none;
                        color: #313131;
                        font-family: "Circular Std";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                      }
                      
                      input[type=checkbox]:checked {
                        background-color: #EF6286;
                        border-color: #EF6286;
                        opacity: 1;
                      }
                      
                      input[type=checkbox]:before {
                        content: '';
                        position: absolute;
                        right: 50%;
                        top: 50%;
                        width: 7px;
                        height: 12px;
                        border: solid #FFF;
                        border-width: 0 2px 2px 0;
                        margin: -1px -1px 0 -1px;
                        transform: rotate(45deg) translate(-50%, -50%);
                        z-index: 2;
                      }
                }
            }
            input[type=text]{
                border: 0.3px solid #959494;
                width: 100%;
                border-radius: 6px;
                height: 48px;
                padding: 12px 17px 13px;
            }
        }
        .patient-contact {
            .patient-number {
                display: flex;
                align-items: center;
                padding-bottom: 10px;
                margin-bottom: 8px;
                justify-content: space-between;
                border-bottom: 0.5px solid #3131314D;
                width: 100%;
                gap: 96px;
                .single {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: end;
                    h4{
                        font-size: 16px;
                        font-weight: 450;
                        line-height: 20.24px;
                        font-family: "Circular Std";
                        color: #313131;
                    }
                    .name {
                        color: #19B3B5;
                    }
                    p{
                        font-size: 16px;
                        font-family: "Circular Std";
                        font-weight: 300;
                        color: #313131;
                    }
                }
                .selected{
                    background-color: #29BCC1 !important;
                    color: white !important;
                }
                .selectBtn {
                    background-color: #959494;
                    border-radius: 4.32px;
                    padding: 4.23px 7.41px 4.23px 7.41px;
                    height: 21.47px;
                    font-weight: 700;
                    font-size: 9.53px;
                    font-family: Nunito;
                    color: #ffffff;
                    text-align: center;
                }
            }
        }

        .continueBtn {
            width: 168px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #29BCC1;
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.8px;
            margin: 41px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .NocontinueBtn {
            width: 168px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 10px;
            background: #CCCCCC;
            color: #666666;
            text-align: center;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.8px;
            margin: 41px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
}